import { Subject, Observable, EMPTY } from 'rxjs';
import { takeUntil, map, tap, catchError } from 'rxjs/operators';
import { action } from 'ts-action';
export { action as createAction, payload, props } from 'ts-action';
export { ofType } from 'ts-action-operators';
class Actions extends Subject {
  dispatch(...actions) {
    actions.forEach(action => {
      this.next(action);
    });
  }
}
const actions = new Actions();
const dispatch = actions.dispatch.bind(actions);
const actionsDispatcher = actions.asObservable();
function coerceArray(value) {
  return Array.isArray(value) ? value : [value];
}
function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}
function isEffect(effect) {
  return typeof effect.sourceFn === 'function' && effect.sourceFn(new Actions()) instanceof Observable;
}
class EffectsManager {
  constructor(config) {
    this.effects = new WeakMap();
    this.destroyEffects$ = new Subject();
    this.config = {
      dispatchByDefault: false,
      ...config
    };
  }
  registerEffects(effects) {
    effects.forEach(effect => {
      this.subscribeEffect(effect);
    });
  }
  removeEffects(effects) {
    effects.forEach(effect => {
      this.unsubscribeEffect(effect);
    });
  }
  removeAllEffects() {
    this.destroyEffects$.next();
    this.effects = new WeakMap();
  }
  subscribeEffect(effect) {
    const source = effect.sourceFn(this.config.customActionsStream || actions);
    const sub = source.pipe(takeUntil(this.destroyEffects$)).subscribe(maybeActions => {
      if (effect.config?.dispatch ?? this.config.dispatchByDefault) {
        const onlyActions = coerceArray(maybeActions).filter(maybeAction => checkAction(maybeAction));
        actions.dispatch(...onlyActions);
      }
    });
    this.effects.set(effect, sub);
  }
  unsubscribeEffect(effect) {
    const sub = this.effects.get(effect);
    sub?.unsubscribe();
    this.effects.delete(effect);
  }
}
function checkAction(action) {
  if (typeof action === 'object' && action !== null && action.type) {
    return true;
  }
  throw new TypeError('Make sure to provide a valid action type or set the option {dispatch: false}');
}
let effectsManager;
function initEffects(config) {
  if (effectsManager) {
    return effectsManager;
  }
  return effectsManager = new EffectsManager(config);
}
function registerEffects(effects) {
  effectsManager.registerEffects(coerceArray(effects));
}
function removeEffects(effects) {
  effectsManager.removeEffects(coerceArray(effects));
}
function removeAllEffects() {
  effectsManager.removeAllEffects();
}
function actionsFactory(storeName) {
  return {
    create: (type, config) => {
      const modifiedStoreName = capitalize(storeName);
      return action(`[${modifiedStoreName}] ${type}`, config);
    }
  };
}
function toPayload() {
  return map(({
    payload
  }) => payload);
}
function toProps() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return map(({
    type,
    ...props
  }) => props);
}
function createEffectFn(factoryFn) {
  return function (destroyed$) {
    const subject = new Subject();
    factoryFn(subject.asObservable()).pipe(takeUntil(destroyed$)).subscribe();
    return function (value) {
      subject.next(value);
    };
  };
}
function tapResult(next, error, complete) {
  return source => source.pipe(tap({
    next,
    error: error ?? console.error,
    complete
  }), catchError(() => EMPTY));
}
function createEffect(factory, config) {
  return {
    sourceFn: factory,
    config
  };
}
export { Actions, EffectsManager, actions, actionsDispatcher, actionsFactory, createEffect, createEffectFn, dispatch, initEffects, isEffect, registerEffects, removeAllEffects, removeEffects, tapResult, toPayload, toProps };