import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { createEffect, dispatch, ofType, tapResult } from '@ngneat/effects';
import { getRegistry } from '@ngneat/elf';
import { filter, from, map, switchMap, tap } from 'rxjs';

import { ISRoutesConfig, ISStoreEnum, ISUser } from '@ess/integrated-search/shared/utils';
import { SharedFeatureServicesUserInteractionService } from '@ess/shared/feature/services';
import { EssGtagHelper } from '@ess/shared/utils/helpers';

import { IntegratedSearchAuthDataAccessAuthActions as AuthActions } from './integrated-search-auth-data-access-auth.actions';
import { IntegratedSearchAuthDataAccessAuthRepository } from './integrated-search-auth-data-access-auth.repository';

import { IntegratedSearchAuthDataAccessAuthService } from '../services';

@Injectable()
export class IntegratedSearchAuthDataAccessAuthEffects {
  verify$ = createEffect(
    ($) =>
      $.pipe(
        ofType(AuthActions.verify),
        filter(() => !this.__repo.verified),
        tap(() => this.__repo.startLoading()),
        map(() => this.__repo.token),
        map((token: string | null) =>
          token ? AuthActions.getCurrentUser({ loginAttempt: false }) : AuthActions.authFailed(),
        ),
      ),
    { dispatch: true },
  );

  login$ = createEffect(($) =>
    $.pipe(
      ofType(AuthActions.login),
      tap(() => this.__repo.startLoading()),
      switchMap(() =>
        this.__authService.login().pipe(
          tapResult(
            (token: string) => {
              this.__repo.saveToken(token);
              EssGtagHelper.sendEvent('isd_login', {
                event_action: `ISD Login`,
                event_category: 'Login',
              });
              dispatch(AuthActions.getCurrentUser({ loginAttempt: true }));
            },
            () => dispatch(AuthActions.loginFailed()),
          ),
        ),
      ),
    ),
  );

  getCurrentUser$ = createEffect(($) =>
    $.pipe(
      ofType(AuthActions.getCurrentUser),
      switchMap(({ loginAttempt }) =>
        this.__authService.currentUser().pipe(
          tapResult(
            (user: ISUser) => {
              this.__repo.saveUser(user);
              dispatch(AuthActions.authSuccess({ loginAttempt }));
            },
            (error: HttpErrorResponse) =>
              dispatch(
                !loginAttempt && error.status === 401 ? AuthActions.sessionExpired() : AuthActions.loginFailed(),
              ),
          ),
        ),
      ),
    ),
  );

  authSuccess$ = createEffect(
    ($) =>
      $.pipe(
        ofType(AuthActions.authSuccess),
        tap(({ loginAttempt }) => {
          this.__repo.finishLoading();
          loginAttempt && this.__router.navigate(['/']);
        }),
        map(() => AuthActions.authSuccessFinished({ user: this.__repo.user! })),
      ),
    { dispatch: true },
  );

  sessionExpired$ = createEffect(
    ($) =>
      $.pipe(
        ofType(AuthActions.sessionExpired),
        tap(() => this.__showSnackbar('Your session expired.\nLog in again.')),
        map(() => AuthActions.authFailed()),
      ),
    { dispatch: true },
  );

  loginFailed$ = createEffect(
    ($) =>
      $.pipe(
        ofType(AuthActions.loginFailed),
        tap(() => this.__showSnackbar('There were some problems.\nTry to log in again.', true)),
        map(() => AuthActions.authFailed()),
      ),
    { dispatch: true },
  );

  authFailed$ = createEffect(($) =>
    $.pipe(
      ofType(AuthActions.authFailed),
      tap(() => {
        this.__repo.finishLoading();
        this.__repo.token && dispatch(AuthActions.clearState());
      }),
    ),
  );

  logout$ = createEffect(
    ($) =>
      $.pipe(
        ofType(AuthActions.logout),
        tap(() => this.__repo.clearUser()),
        switchMap(() => from(this.__router.navigate(ISRoutesConfig.login()))),
        map(() => AuthActions.clearState()),
      ),
    { dispatch: true },
  );

  clearState$ = createEffect(($) =>
    $.pipe(
      ofType(AuthActions.clearState),
      tap(() => {
        this.__authService.clearTokenManager();
        this.__resetStores();
      }),
    ),
  );

  private readonly __persistedStores = [ISStoreEnum.FILTERS, ISStoreEnum.CHART_TABS];

  constructor(
    private readonly __authService: IntegratedSearchAuthDataAccessAuthService,
    private readonly __repo: IntegratedSearchAuthDataAccessAuthRepository,
    private readonly __router: Router,
    private readonly __uiService: SharedFeatureServicesUserInteractionService,
  ) {}

  private __resetStores(): void {
    getRegistry().forEach((store) => !this.__persistedStores.includes(store.name as ISStoreEnum) && store.reset());
  }

  private __showSnackbar(message: string | null, error = false): void {
    message &&
      this.__uiService.openSnackbar({
        data: { type: error ? 'error' : 'info', text: message },
      });
  }
}
