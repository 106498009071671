import { Injectable } from '@angular/core';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { excludeKeys, localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { BehaviorSubject, Observable } from 'rxjs';

import { ISStoreEnum, ISUser } from '@ess/integrated-search/shared/utils';
import { SharedStore } from '@ess/shared/utils/models';

interface StoreProps {
  access_token: string | null;
  user: ISUser | null;
  loginLoading: boolean;
}

type Store = SharedStore<StoreProps>;

@Injectable()
export class IntegratedSearchAuthDataAccessAuthRepository {
  private readonly __store: Store;

  get verified$(): Observable<boolean> {
    return this.__verified$.asObservable();
  }

  get user$(): Observable<ISUser | null> {
    return this.__store.pipe(select((state) => state.user));
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.__store.pipe(select((state) => !!state.user));
  }

  get loginLoading$(): Observable<boolean> {
    return this.__store.pipe(select((state) => state.loginLoading));
  }

  get token(): string | null {
    return this.__store.getValue().access_token;
  }

  get verified(): boolean {
    return this.__verified$.getValue();
  }

  get user(): ISUser | null {
    return this.__store.getValue().user;
  }

  private readonly __verified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.__store = this.__createStore();
    this.__persistState();
  }

  saveToken(access_token: string): void {
    this.__store.update(setProps({ access_token }));
  }

  saveUser(user: ISUser): void {
    this.__store.update(setProps({ user }));
  }

  clearUser(): void {
    this.__store.update(setProps({ user: null }));
  }

  startLoading(): void {
    this.__store.update(setProps({ loginLoading: true }));
  }

  finishLoading(): void {
    this.__store.update(setProps({ loginLoading: false }));
    this.__verified$.next(true);
  }

  private __createStore(): Store {
    return createStore(
      { name: ISStoreEnum.AUTH },
      withProps<StoreProps>({
        access_token: null,
        user: null,
        loginLoading: false,
      }),
    );
  }

  private __persistState(): void {
    persistState(this.__store, {
      key: 'integratedSearchAuthDataAccessAuth',
      storage: localStorageStrategy,
      source: () => this.__store.pipe(excludeKeys(['loginLoading', 'user'])),
    });
  }
}
