import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { TokenResponse, Tokens } from '@okta/okta-auth-js';
import { from, map, Observable, tap } from 'rxjs';

import { ISUser } from '@ess/integrated-search/shared/utils';
import { SharedDataAccessApiService } from '@ess/shared/data-access';

@Injectable()
export class IntegratedSearchAuthDataAccessAuthService {
  constructor(
    @Inject(OKTA_AUTH) private readonly __oktaAuth: OktaAuth,
    private readonly __apiService: SharedDataAccessApiService,
  ) {}

  currentUser(): Observable<ISUser> {
    return this.__apiService.get<ISUser>('users/current');
  }

  login(): Observable<string> {
    return from(this.__oktaAuth.token.getWithPopup({})).pipe(
      tap((resp: TokenResponse) => this.__setTokenManager(resp.tokens)),
      map((resp) => resp.tokens?.idToken?.idToken ?? ''),
    );
  }

  refreshToken(): Observable<string> {
    return from(this.__oktaAuth.token.renewTokens()).pipe(
      tap((resp: Tokens) => this.__setTokenManager(resp)),
      map((resp: Tokens) => resp.idToken?.idToken ?? ''),
    );
  }

  clearTokenManager(): void {
    this.__oktaAuth.tokenManager.clear();
  }

  private __setTokenManager(tokens: Tokens): void {
    this.__oktaAuth.tokenManager.setTokens(tokens);
  }
}
