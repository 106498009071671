import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { first, map, switchMap } from 'rxjs';

import { ISRoutesConfig } from '@ess/integrated-search/shared/utils';

import { IntegratedSearchAuthDataAccessAuthFacade } from '../state';

export const IS_AUTH_GUARD: CanActivateFn = () => {
  const redirectTree = inject(Router).createUrlTree(ISRoutesConfig.login());
  const authFacade = inject(IntegratedSearchAuthDataAccessAuthFacade);
  authFacade.init();
  return authFacade.isVerified$.pipe(
    first(Boolean),
    switchMap(() => authFacade.isAuthenticated$),
    map((access: boolean) => access || redirectTree),
  );
};
