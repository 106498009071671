import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const BACKEND_URL = new InjectionToken<string>('BackendUrl');

@Injectable()
export class SharedDataAccessApiService {
  private readonly __API_URL = this.__backendUrl;

  private get __headers(): HttpHeaders {
    const headersConfig = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return new HttpHeaders(headersConfig);
  }

  constructor(@Inject(BACKEND_URL) private __backendUrl: string, private __http: HttpClient) {}

  get<T>(url: string, options = {}): Observable<T> {
    return this.__http.get<T>(`${this.__API_URL}${url}`, {
      headers: this.__headers,
      ...options,
    });
  }

  externalGet<T>(url: string, options = {}): Observable<T> {
    return this.__http.get<T>(url, { headers: this.__headers, ...options });
  }

  post<T, D>(url: string, data?: D, options = {}): Observable<T> {
    return this.__http.post<T>(`${this.__API_URL}${url}`, JSON.stringify(data), {
      headers: this.__headers,
      ...options,
    });
  }

  put<T, D>(url: string, data: D): Observable<T> {
    return this.__http.put<T>(`${this.__API_URL}${url}`, JSON.stringify(data), {
      headers: this.__headers,
    });
  }

  delete<T, D>(url: string, body: D): Observable<T> {
    return this.__http.delete<T>(`${this.__API_URL}${url}`, {
      headers: this.__headers,
      body,
    });
  }

  patch<T, D>(url: string, data: D): Observable<T> {
    return this.__http.patch<T>(`${this.__API_URL}${url}`, JSON.stringify(data), {
      headers: this.__headers,
    });
  }
}
