import { ModuleWithProviders, NgModule } from '@angular/core';
import { provideEffects } from '@ngneat/effects-ng';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

import { IntegratedSearchAuthDataAccessAuthService } from './services';
import { IntegratedSearchAuthDataAccessAuthEffects, IntegratedSearchAuthDataAccessAuthFacade } from './state';
import { IntegratedSearchAuthDataAccessAuthRepository } from './state/integrated-search-auth-data-access-auth.repository';

@NgModule({
  imports: [OktaAuthModule],
})
export class IntegratedSearchAuthDataAccessModule {
  static forRoot(oktaConfig: Partial<OktaAuthOptions>): ModuleWithProviders<IntegratedSearchAuthDataAccessModule> {
    const oktaAuth: OktaAuth = new OktaAuth(oktaConfig);
    return {
      ngModule: IntegratedSearchAuthDataAccessModule,
      providers: [
        provideEffects(IntegratedSearchAuthDataAccessAuthEffects),
        IntegratedSearchAuthDataAccessAuthService,
        IntegratedSearchAuthDataAccessAuthFacade,
        IntegratedSearchAuthDataAccessAuthRepository,
        {
          provide: OKTA_CONFIG,
          useValue: { oktaAuth },
        },
      ],
    };
  }
}
