import { Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { Params } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, catchError, distinctUntilChanged, EMPTY, Observable, Subject, switchMap, tap } from 'rxjs';

import { EssRequestParamsHelper } from '@ess/shared/utils/helpers';
import { SharedListResponse } from '@ess/shared/utils/models';

import { SharedDataAccessApiService } from './shared-data-access-api.service';

/**
 * @param U - list item interface
 * @param T - list object interface (SharedListResponse<U> by default)
 */
@UntilDestroy()
@Injectable()
export abstract class AbstractSharedDataAccessListService<U, T = SharedListResponse<U>> {
  readonly data$: Observable<T | null>;
  readonly dataError$: Observable<string | null>;
  readonly dataLoading: Signal<boolean>;

  protected readonly _data$: BehaviorSubject<T | null> = new BehaviorSubject<T | null>(null);
  protected readonly _dataError$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  protected readonly _dataLoading: WritableSignal<boolean> = signal<boolean>(true);
  protected _method: 'get' | 'post' = 'get';

  private readonly __fetchList$: Subject<Params> = new Subject<Params>();

  constructor(protected readonly _apiService: SharedDataAccessApiService) {
    this.data$ = this._data$.asObservable();
    this.dataError$ = this._dataError$.asObservable();
    this.dataLoading = this._dataLoading.asReadonly();
    this.__listenListFetch();
  }

  protected abstract get resourcePath(): string;

  fetchList(params: Params): void {
    this.__fetchList$.next(params);
  }

  resetListState(): void {
    this.__setLoading(true);
    this._data$.next(null);
    this._dataError$.next(null);
  }

  private __setLoading(loading: boolean): void {
    this._dataLoading.set(loading);
  }

  private __setError(error: string | null): void {
    this._dataError$.next(error);
  }

  private __listenListFetch(): void {
    this.__fetchList$
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        tap(() => this.__setLoading(true)),
        switchMap((params: Params) =>
          this.__getSource(params).pipe(
            catchError(() => this.__handleError()),
            tap({ complete: () => this.__setLoading(false) }),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe((res: T) => {
        this._data$.next(res);
        this.__setError(null);
      });
  }

  private __getSource(params: Params): Observable<T> {
    return this._method === 'get'
      ? this._apiService.get<T>(this.resourcePath, { params: EssRequestParamsHelper.getParams(params) })
      : this._apiService.post<T, Params>(this.resourcePath, EssRequestParamsHelper.getData(params));
  }

  private __handleError(): Observable<never> {
    this._data$.next(null);
    // TODO: improve handling backend errors
    this.__setError('Something went wrong during loading data. Try again later.');
    return EMPTY;
  }
}
