import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { IntegratedSearchAuthDataAccessAuthRepository } from '../state/integrated-search-auth-data-access-auth.repository';

export const IS_AUTH_INTERCEPTOR: HttpInterceptorFn = (req, next) => {
  const token: string | null = inject(IntegratedSearchAuthDataAccessAuthRepository).token;
  if (token && !req.url.includes('token')) {
    req = req.clone({
      setHeaders: { Authorization: 'Bearer ' + token },
    });
  }
  return next(req);
};
