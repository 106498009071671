import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { BehaviorSubject, catchError, first, Observable, switchMap, tap, throwError } from 'rxjs';

import { IntegratedSearchAuthDataAccessAuthService } from '../services';
import { IntegratedSearchAuthDataAccessAuthFacade } from '../state';

let isRefreshing = false;
const refreshToken$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

export const IS_REFRESH_TOKEN_INTERCEPTOR: HttpInterceptorFn = (req, next) => {
  const authService: IntegratedSearchAuthDataAccessAuthService = inject(IntegratedSearchAuthDataAccessAuthService);
  const facade: IntegratedSearchAuthDataAccessAuthFacade = inject(IntegratedSearchAuthDataAccessAuthFacade);
  return next(req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        return refreshToken(req, next, authService, facade, req.url.endsWith('users/current'));
      }
      return throwError(() => error);
    }),
  );
};

const refreshToken = (
  request: HttpRequest<any>,
  next: HttpHandlerFn,
  authService: IntegratedSearchAuthDataAccessAuthService,
  facade: IntegratedSearchAuthDataAccessAuthFacade,
  isInitializing: boolean,
): Observable<HttpEvent<any>> => {
  if (!isRefreshing) {
    isRefreshing = true;
    refreshToken$.next(null);

    return authService.refreshToken().pipe(
      tap((token: string) => {
        facade.saveToken(token);
        isRefreshing = false;
        refreshToken$.next(token);
      }),
      switchMap((token: string) => next(newTokenRequest(request, token))),
      catchError((error) => {
        !isInitializing && facade.logout();
        return throwError(() => error);
      }),
    );
  }

  return refreshToken$.pipe(
    first((token): token is string => !!token),
    switchMap((token: string) => next(newTokenRequest(request, token))),
  );
};

const newTokenRequest = (request: HttpRequest<any>, token: string): HttpRequest<any> => {
  request = request.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
  return request;
};
