import { Injectable } from '@angular/core';
import { Actions } from '@ngneat/effects-ng';
import { Observable } from 'rxjs';

import { ISUser } from '@ess/integrated-search/shared/utils';

import { IntegratedSearchAuthDataAccessAuthActions as AuthActions } from './integrated-search-auth-data-access-auth.actions';
import { IntegratedSearchAuthDataAccessAuthRepository } from './integrated-search-auth-data-access-auth.repository';

@Injectable()
export class IntegratedSearchAuthDataAccessAuthFacade {
  readonly isAuthenticated$: Observable<boolean> = this.__repo.isAuthenticated$;
  readonly loginLoading$: Observable<boolean> = this.__repo.loginLoading$;
  readonly user$: Observable<ISUser | null> = this.__repo.user$;
  readonly isVerified$: Observable<boolean> = this.__repo.verified$;

  constructor(
    private readonly __actions: Actions,
    private readonly __repo: IntegratedSearchAuthDataAccessAuthRepository,
  ) {}

  init(): void {
    this.__actions.dispatch(AuthActions.verify());
  }

  login(): void {
    this.__actions.dispatch(AuthActions.login());
  }

  saveToken(token: string): void {
    this.__repo.saveToken(token);
  }

  logout(): void {
    this.__actions.dispatch(AuthActions.logout());
  }
}
