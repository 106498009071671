/* eslint-disable @typescript-eslint/member-ordering */
import { actionsFactory, props } from '@ngneat/effects';

import { ISStoreEnum, ISUser } from '@ess/integrated-search/shared/utils';

export class IntegratedSearchAuthDataAccessAuthActions {
  private static readonly __factory = actionsFactory(ISStoreEnum.AUTH);

  static readonly verify = IntegratedSearchAuthDataAccessAuthActions.__factory.create(
    'Verify if access token is present',
  );
  static readonly login = IntegratedSearchAuthDataAccessAuthActions.__factory.create('Begin user login process');

  static readonly getCurrentUser = IntegratedSearchAuthDataAccessAuthActions.__factory.create(
    'Fetch current user data',
    props<{ loginAttempt: boolean }>(),
  );

  static readonly authSuccess = IntegratedSearchAuthDataAccessAuthActions.__factory.create(
    'Successful authentication process',
    props<{ loginAttempt: boolean }>(),
  );
  static readonly authSuccessFinished = IntegratedSearchAuthDataAccessAuthActions.__factory.create(
    'Successful authentication process finished',
    props<{ user: ISUser }>(),
  );

  static readonly sessionExpired = IntegratedSearchAuthDataAccessAuthActions.__factory.create('User session expired');
  static readonly loginFailed = IntegratedSearchAuthDataAccessAuthActions.__factory.create('Failed login process');
  static readonly authFailed = IntegratedSearchAuthDataAccessAuthActions.__factory.create(
    'Failed user authentication process',
  );

  static readonly logout = IntegratedSearchAuthDataAccessAuthActions.__factory.create('Logout user');
  static readonly clearState = IntegratedSearchAuthDataAccessAuthActions.__factory.create('Clear app state');
}
